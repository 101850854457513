import * as React from "react";
import { graphql, HeadFC, Link } from "gatsby";

import { useGakka } from "../../hooks/useGakka/useGakka";

import Layout from "../../components/layouts/Default/Default";
import { ResponsiveBlock } from "../../components/layouts/ResponsiveBlock/ResponsiveBlock";

import { CommonHead } from "../../components/commons/Head/Head";

import Hr from "../../components/elements/Hr/Hr";
import { KoreanDivisionAbstruct } from "../../components/elements/DivisionAbstruct/DivisionAbstruct";
import {
  KTPageHeader,
  KTSubPageHeader,
  KTMainLink,
  KTMovieHeader,
} from "../../components/elements/KoreanTop/KoreanTop";
import SnsShare from "../../components/elements/SnsShare/SnsShare";
import Vimeo from "../../components/elements/Vimeo/Vimeo";

export const query = graphql`
  query {
    allSettingsJson {
      nodes {
        id
        siteTitle
      }
    }
  }
`;

const IndexPage = ({ data }: any) => {
  const title = data.allSettingsJson?.nodes[0].siteTitle;
  return (
    <Layout title={title} pageType="TopPage">
      <div className="tw-c-page-top md:tw-c-page-top-md">
        {/* メイン動画 */}
        <Vimeo
          vimeoId="944314315"
          privacyHash="80061de9e5"
          autoPlay
          controls
          muted
        />
        {/* 韓国語専用ヘッダー */}
        <KTPageHeader
          title="大好きな韓国を多くの人へ"
          subtitle="모두에게 전하고 싶은 매력적인 한국"
        />
        {/* 韓国語専用ヘッダー(小) */}
        <KTSubPageHeader title="韓国語から広がる世界" />
        <section className="tw-bg-[#f988b7]">
          <KTMainLink
            bgColorClass="tw-bg-[#B767C5]"
            imageSrc="/images/korean/kv‐meet.jpg"
            linkUrl="/korean/meet/"
            koreanTitle="만나다"
            japaneseTitle="出会う"
            lines={[
              { main: "친구", sub: "友達" },
              { main: "선생님", sub: "先生" },
              { main: "JCFL", sub: "", font: "tw-font-sans" },
            ]}
            linesBgColorClass="tw-bg-[#FCEAFF]"
          />
          <KTMainLink
            bgColorClass="tw-bg-[#EFB61C]"
            imageSrc="/images/korean/kv‐feel.jpg"
            linkUrl="/korean/feel/"
            koreanTitle="느끼다"
            japaneseTitle="感じる"
            lines={[
              { main: "문화", sub: "文化" },
              { main: "엔터테인먼트", sub: "エンタメ" },
              { main: "신오쿠보", sub: "新大久保" },
            ]}
            linesBgColorClass="tw-bg-[#FBF0D1]"
            reversal
          />
          <KTMainLink
            bgColorClass="tw-bg-[#78C375]"
            imageSrc="/images/korean/kv‐learn.jpg"
            linkUrl="/korean/learn/"
            koreanTitle="배우다"
            japaneseTitle="学ぶ"
            lines={[
              { main: "어학", sub: "語学" },
              { main: "자격", sub: "資格" },
              { main: "통역", sub: "通訳" },
            ]}
            linesBgColorClass="tw-bg-[#EDFFED]"
          />
          <KTMainLink
            bgColorClass="tw-bg-[#2BBFBF]"
            imageSrc="/images/korean/kv‐realize.jpg"
            linkUrl="/korean/realize/"
            koreanTitle="이루다"
            japaneseTitle="叶う"
            lines={[
              { main: "일", sub: "仕事" },
              { main: "유학", sub: "留学" },
              { main: "나의 꿈", sub: "私の夢" },
            ]}
            linesBgColorClass="tw-bg-[#DCFFFF]"
            reversal
          />
        </section>
        <KTMovieHeader title="動画でみる韓国語専攻" />
        <ResponsiveBlock bgColor="tw-bg-baseBlack">
          <Vimeo
            vimeoId="944314133"
            privacyHash="e43d6c8741"
            controls
            muted
            text1="만나다"
            text2="出会う"
            text3="「韓国が好き！」という気持ちを大切に、共感できる友人や先生と会える場所。"
            text4="日本外国語専門学校"
          />
          <Vimeo
            vimeoId="944314699"
            privacyHash="62e4012259"
            controls
            muted
            text1="느끼다"
            text2="感じる"
            text3="学内での文化体験だけでなく、エンタメを通して韓国を感じる。新大久保への立地も自慢の一つ。"
            text4="日本外国語専門学校"
          />
          <Vimeo
            vimeoId="944314476"
            privacyHash="d5b3e8c21c"
            controls
            muted
            text1="배우다"
            text2="学ぶ"
            text3="レベル別少人数の自分に合ったクラスで、２年間ネイティブから韓国語と英語を学ぶ。"
            text4="日本外国語専門学校"
          />
          <Vimeo
            vimeoId="944314595"
            privacyHash="fcd24e7fa1"
            controls
            muted
            text1="이루다"
            text2="叶う"
            text3="韓国語と英語の力で、将来の幅を無限に広げ、グローバルに活躍できる人材を育てる。"
            text4="日本外国語専門学校"
          />
        </ResponsiveBlock>
        <KoreanDivisionAbstruct spaceBottom />
        <Hr />
        <SnsShare path="/korean" title={title} spaceBottom />
      </div>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = ({ data }: any) => (
  <CommonHead
    ogTitle={`アジア・ヨーロッパ言語科韓国語＋英語専攻 - ${data.allSettingsJson?.nodes[0].siteTitle}`}
    ogDescription="韓国が好き、韓国語が好き、Kpopが好き、韓国文化が好き。そこがすべての始まり。"
    ogImageFilepath="/images/flight/AL-TOP1-1.jpg"
  >
    <title>
      アジア・ヨーロッパ言語科韓国語＋英語専攻 -
      {data.allSettingsJson?.nodes[0].siteTitle}
    </title>
  </CommonHead>
);
