import * as React from "react";

const Vimeo = ({
  vimeoId,
  privacyHash,
  autoPlay = false,
  controls = false,
  muted = false,
  text1 = "",
  text2 = "",
  text3 = "",
  text4 = "",
}: any) => {
  return (
    <>
      <div style={{ position: "relative", padding: "56.25% 0 0 0" }}>
        <iframe
          src={`https://player.vimeo.com/video/${vimeoId}?h=${privacyHash}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&controls=${controls}&autoplay=${autoPlay}&muted=${muted}`}
          //frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          //title={title}
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
      {text1 || text2 || text3 | text4 ? (
        <div className="tw-flex tw-items-center tw-px-4 tw-py-3 tw-bg-baseBlack tw-text-white md:tw-px-0 md:tw-py-4">
          <img
            className="tw-mr-4 tw-max-w-[40px] tw-max-h-[40px] md:tw-mr-8 md:tw-max-w-[64px] md:tw-max-h-[64px]"
            alt=""
            src="/images/korean/logo400x400.png"
          />
          <div className="tw-min-w-[64px] tw-font-bold md:tw-min-w-[110px]">
            <p className="tw-mb-1 tw-font-nanum tw-text-lg tw-leading-4 md:tw-text-[28px] md:tw-leading-8">
              {text1}
            </p>
            <p className="tw-text-xs md:tw-text-lg">{text2}</p>
          </div>
          <div>
            <p className="tw-text-[#dddddd] tw-text-xs md:tw-mb-1 md:tw-text-[24px] md:tw-leading-8">
              {text3}
            </p>
            <p className="tw-text-[#bbbbbb] tw-text-xss md:tw-text-sm">
              {text4}
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Vimeo;
