import * as React from "react";
import { graphql, HeadFC, Link } from "gatsby";

import { FaArrowDown, FaArrowRight, FaPlay } from "react-icons/fa";

import { useGakka } from "../../../hooks/useGakka/useGakka";

import { ResponsiveBlock } from "../../layouts/ResponsiveBlock/ResponsiveBlock";

import H2 from "../H2/H2";

/**
 * 韓国語ヘッダー
 **/
export const KTPageHeader = ({ title, subtitle }: any) => {
  const gakka = useGakka();
  return (
    <div className={`tw-px-4 tw-py-8 tw-bg-${gakka.color} md:tw-py-12`}>
      <ResponsiveBlock bgColor="">
        <h1 className="tw-pl-4 tw-border-l-4 tw-border-white tw-text-white md:tw-border-l-[6px]">
          <p className="tw-text-base tw-font-nanum tw-font-bold">{subtitle}</p>
          <p className="tw-text-htmlH1 tw-font-black md:tw-text-[2rem]">
            {title}
          </p>
        </h1>
      </ResponsiveBlock>
    </div>
  );
};

export const KTSubPageHeader = ({ title }: any) => {
  return (
    <ResponsiveBlock bgColor="">
      <h2 className="tw-p-6 tw-text-htmlH2 tw-font-bold md:tw-px-0 md:tw-py-12 md:tw-text-[2rem]">
        {title}
      </h2>
    </ResponsiveBlock>
  );
};

export const KTMainLink = ({
  bgColorClass,
  imageSrc,
  linkUrl,
  koreanTitle,
  japaneseTitle,
  lines,
  linesBgColorClass,
  reversal,
}: any) => {
  return (
    <div className="CategorySection tw-relative">
      <Link to={linkUrl} className="tw-block tw-w-full tw-h-full">
        <div
          className={`tw-absolute tw-top-0 ${
            reversal
              ? "tw-left-0 tw-rounded-tr-full tw-rounded-br-full"
              : "tw-right-0 tw-rounded-tl-full tw-rounded-bl-full"
          } tw-w-[calc(100vw-120px)] tw-h-[143px] ${bgColorClass} tw-z-0 md:tw-w-[calc(100vw-388px)] md:tw-h-[424px]`}
        ></div>
        <div
          className={`tw-relative tw-w-[358px] tw-m-auto tw-py-4 md:tw-w-[1160px] md:tw-py-20 ${
            reversal ? "tw-text-right" : ""
          }`}
        >
          {/* 画像 */}
          <img
            alt=""
            src={imageSrc}
            className={`tw-inline-block tw-w-[240px] tw-h-[173px] tw-rounded-tl-full tw-rounded-tr-full ${
              reversal ? "tw-rounded-br-full" : "tw-rounded-bl-full"
            } tw-z-0 md:tw-w-[685px] md:tw-h-[494px]`}
          />
          {/* タイトル */}
          <div
            className={`tw-absolute tw-top-3 ${
              reversal ? "tw-left-0" : "tw-right-0"
            } tw-text-white md:tw-top-16`}
          >
            <div className="tw-flex">
              <div className="tw-font-bold">
                <p className="tw-font-nanum tw-text-[22px] md:tw-text-[4rem]">
                  {koreanTitle}
                </p>
                <p className="tw-text-base md:tw-text-[2rem]">
                  {japaneseTitle}
                </p>
              </div>
              <div className="tw-mt-1 tw-ml-4 tw-text-2xl md:tw-mt-4 md:tw-ml-52 md:tw-text-[64px]">
                <FaArrowRight />
              </div>
            </div>
          </div>
          {/* 項目 */}
          <ul
            className={`tw-list-disc tw-absolute ${
              reversal ? "tw-left-0" : "tw-right-0"
            } tw-bottom-4 tw-w-[160px] tw-pl-7 tw-pr-3 tw-py-3 ${linesBgColorClass} ${
              reversal ? "tw-rounded-tl-lg" : "tw-rounded-tr-lg"
            } tw-rounded-bl-lg tw-rounded-br-lg tw-text-left md:tw-bottom-20 md:tw-w-[552px] md:tw-pl-40 md:tw-pr-10 md:tw-py-16 ${
              reversal ? "md:tw-rounded-tl-3xl" : "md:tw-rounded-tr-3xl"
            } md:tw-rounded-bl-3xl md:tw-rounded-br-3xl`}
          >
            {lines.map((line: any) => {
              return (
                <li className="[&:not(:last-child)]:tw-mb-2 tw-text-[#775756] tw-text-xs tw-font-bold md:[&:not(:last-child)]:tw-mb-10 md:tw-text-[2rem]">
                  <span className={line.font ? line.font : "tw-font-nanum"}>
                    {line.main}
                  </span>
                  {line.sub && (
                    <span className="tw-ml-2 tw-text-[9px] tw-text-[#9C8886] tw-font-bold md:tw-ml-4 md:tw-text-2xl">
                      {line.sub}
                    </span>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </Link>
    </div>
  );
};

export const KTMovieHeader = ({ title }: any) => {
  return (
    <ResponsiveBlock bgColor="tw-bg-baseBlack">
      <section className="md:tw-py-10">
        <h2 className="tw-pt-6 tw-px-4 tw-pb-4 tw-bg-baseBlack tw-border-l-8 tw-border-gakka3 md:tw-pt-4 md:tw-pb-0 md:tw-px-0 md:tw-pl-6">
          <div className="tw-flex tw-items-center tw-pb-2 tw-border-b-2 tw-text-white tw-text-htmlH2 tw-font-bold md:tw-pb-4 md:tw-text-[2rem]">
            <FaPlay />
            <span className="tw-ml-3 md:tw-ml-6">{title}</span>
          </div>
        </h2>
      </section>
    </ResponsiveBlock>
  );
};
